import { Component } from '@angular/core';

import { ScrollingListService, ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';

import { ModalService } from 'src/app/components/modals/modal.service';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {

  filterConfig = {
    placeholder: 'Search Users'
  };

  search = {
    companyId: this.companyStateService.getSelectedCompanyId(),
    sortBy: 'username',
    reverse: false,
    name: 'Users'
  };
  users = this.ScrollingListService(this.userApiService.list.bind(this.userApiService), this.search);

  constructor(
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private ScrollingListService: ScrollingListService,
    private userApiService: UserApiService,
    private modalService: ModalService,
    private processErrorCode: ProcessErrorCode
  ) { }

  exportUsers () {
    this.users.loadingItems = false;
    this.users.errorMessage = '';
    this.users.apiError = '';
    this.users.errorCodes = [];

    this.modalService.confirm('Export users?',
      'An export file will be prepared and emailed to you at <b>' + this.userStateService.getUserEmail() +
      '</b> once ready.<br/> Please ensure your email is configured to accept emails from <b>no-reply@risevision.com</b>.',
      'Export', 'Cancel').then(() => {

      this.users.loadingItems = true;
      this.userApiService.export(this.companyStateService.getSelectedCompanyId()).then(() => { })
      .catch((error) => {
        this.users.errorMessage = 'Failed to export users.';
        this.users.apiError = this.processErrorCode(error);
      })
      .finally(() => {
        this.users.loadingItems = false;
      });
    });
  }

}
