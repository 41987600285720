<mat-form-field class="w-100 remove-subscript emails-field" appearance="outline">
  <mat-chip-grid #chipGrid aria-label="Enter fruits" name="grid" [formControl]="email">
    @for (email of emailsList; track email; let index = $index) {
      <mat-chip-row
        (removed)="onRemove(index)"
        [editable]="false"
      >
        {{email}}
        <button matChipRemove [attr.aria-label]="'remove ' + email">
          <streamline-icon ariaLabel="Remove {{email}}" name="close" width="12" height="12"></streamline-icon>
        </button>
      </mat-chip-row>
    }
    <input
      name="input"
      [placeholder]="placeholderText"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (keyup)="onInputChange($event.target.value)"
      (change)="onInputChange($event.target.value)"
      (blur)="_validate($event.target.value)"
      (matChipInputTokenEnd)="onTokenEnd($event)"
    />
  </mat-chip-grid>
</mat-form-field>