<ng-template #teacherPopover>Assign displays to your teachers allowing them to manage their content in a simple interface.
  <a class="madero-link u_clickable" href="#" (click)="helpWidgetService.openArticle('522', 'https://help.risevision.com/hc/en-us/articles/8181799293204-How-do-teachers-create-content-')">More details</a>
</ng-template>
<div class="form-group required"
  [ngClass]="{ 'has-error' : firstName.invalid && (!firstName.pristine || userForm.submitted) }">
  <label for="user-settings-first-name">
    First Name:
  </label>
  <input #firstName="ngModel" id="user-settings-first-name"
    type="text" required name="firstName"
    class="form-control"
    [(ngModel)]="user.firstName"
    />
  <p [shown]="firstName.invalid && (!firstName.pristine || userForm.submitted)"
    class="help-block validation-error-message-firstName">First Name is required.</p>
</div>
<div class="form-group required"
  [ngClass]="{ 'has-error' : lastName.invalid && (!lastName.pristine || userForm.submitted) }">
  <label for="user-settings-last-name">
    Last Name:
  </label>
  <input #lastName="ngModel" id="user-settings-last-name"
    type="text" required name="lastName"
    class="form-control"
    [(ngModel)]="user.lastName"
    />
  <p [shown]="lastName.invalid && (!lastName.pristine || userForm.submitted)"
    class="help-block validation-error-message-lastName">Last Name is required.</p>
</div>
<div class="form-group">
  <label for="company-role">
    Company Role:
  </label>
  <select name="companyRole" id="company-role" class="form-control selectpicker" [(ngModel)]="user.companyRole">
    <option value="" [shown]="false">&lt; Select Role &gt;</option>
    @for (role of COMPANY_ROLE_FIELDS; track role) {
      <option value="{{role[1]}}">{{role[0]}}</option>
    }
  </select>
</div>
<div class="form-group">
  <label for="user-settings-phone">
    Phone Number:
  </label>
  <input
    id="user-settings-phone"
    type="tel"
    name="phone"
    class="form-control"
    [(ngModel)]="user.telephone"
  />
</div>
<div class="form-group required"
  [ngClass]="{ 'has-error' : email.invalid && (!email.pristine || userForm.submitted) }">
  <label for="user-settings-email">
    Work Email:
  </label>
  <input
    #email="ngModel"
    id="user-settings-email"
    type="email" required name="email"
    class="form-control"
    [(ngModel)]="user.email"
    [pattern]="EMAIL_REGEX"
    />
  <p [shown]="email.invalid && (!email.pristine || userForm.submitted)"
    class="help-block validation-error-message-email">A valid email address is required.</p>
</div>
<div class="form-group" *requireRole="'!ap'">
  <label>
    Roles:
  </label>
  @for (role of USER_ROLES; track role) {
    <div [shown]="editRoleVisible(role)">
      <div class="flex-row">
        <mat-checkbox
          id="checkbox-user-role-{{role.key}}"
          name="checkbox-user-role-{{role.key}}"
          [disabled]="!editRoleAllowed(role)"
          aria-labelledby="label-user-role-{{role.key}}"
          ngDefaultControl
          [(ngModel)]="role.checked"
          (ngModelChange)="onCheckboxChange(role)">
          <span class="font-weight-bold" [ngClass]="{ 'rise-admin-only': role.admin }">{{role.value}}</span>
        </mat-checkbox>
        @if (role.key === 'ap') {
          <streamline-icon
            name="help"
            ariaLabel="More information"
            class="help ml-2"
            [outsideClick]="true"
            [popover]="teacherPopover"
            >
          </streamline-icon>
        }
        @if (role.key === 'ap' && !teacherRoleAvailable) {
          <div class="ml-2">
            <span [shown]="teacherRoleAvailable === null">
              <a href="#" class="madero-link" uiSref="apps.purchase.home" (click)="closeAllModals()">Subscription Required</a>
            </span>
            <span [shown]="teacherRoleAvailable !== null">
              <a href="#" class="madero-link" (click)="showUpgradePlanModal()">Plan Upgrade Required</a>
            </span>
          </div>
        }
      </div>
    </div>
  }
</div>
