import { Injectable } from '@angular/core';
import { StorageAPILoader } from 'src/app/ajs-upgraded-providers';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

type AssetError = {
  code?: string,
  message?: string
};

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(
    private storageManagerService: StorageManagerService,
    private storageAPILoader: StorageAPILoader,
    private companyStateService: CompanyStateService
  ) { }

  private _parseErrors(metadata: { [key: string]: string }): AssetError[] {
    const errors: AssetError[] = [];
    let i;

    for (const key in metadata) {
      if (key.startsWith('assetError_documentImages_code')) {
        i = parseInt(key.substring(30)) - 1;
        errors[i] = errors[i] || {};
        errors[i].code = metadata[key];

      } else if (key.startsWith('assetError_documentImages_message')) {
        i = parseInt(key.substring(33)) - 1;
        errors[i] = errors[i] || {};
        errors[i].message = metadata[key];
      }
    }
    return errors;
  }

  private _fileCount(result: any): number {
    if (result?.files) {
      const files = result.files[0]?.metadata?.assetSuccess_documentImages_imageCount;
      return files ? parseInt(files) : 0;
    }
    return 0;
  }

  private _getFiles(file: string, companyId: string): Promise<any> {
    return this.storageAPILoader()
      .then((storageApi) => {
        return storageApi.files.get({
          companyId: companyId || this.companyStateService.getSelectedCompanyId(),
          file
        });
      })
      .then((response) => {
        if (response.result?.files) {
          const firstFile = response.result.files[0];
          const errors = firstFile.metadata ? this._parseErrors(firstFile.metadata) : [];
          if (errors.length) {
            throw new Error(errors[0].message);
          }
          return response.result;
        }
        throw new Error('Storage files could not be retrieved.');
      });
  }

  requestAssets(file: string, companyId?: string): Promise<number> {
    return this.storageAPILoader()
      .then((storageApi) => {
        return storageApi.assets.request({
          companyId: companyId || this.companyStateService.getSelectedCompanyId(),
          assetType: 'documentImages',
          file
        });
      }).then((response) => {
        return new Promise((resolve, reject) => {
          this._pollFiles(file, companyId, resolve, reject);
        });
      }).catch((error) => {
        throw new Error(error.result?.error?.message || error.message || 'A server error has occurred.');
      });
  }

  private _pollTimeout;
  private _pollFiles(
    file: string,
    companyId: string,
    resolve: (value: unknown) => void,
    reject: (reason: any) => void,
    retries?: number
  ) {
    return this._getFiles(file, companyId)
      .then((result) => {
        retries = retries || 0;

        const files = result ? this._fileCount(result) : 0;
        if (files) {
          clearTimeout(this._pollTimeout);
          return resolve(files);

        } else if (retries < 60) {
          this._pollTimeout = setTimeout(() => {
            this._pollFiles(file, companyId, resolve, reject, retries + 1);
          }, 1000);
        } else {
          reject('The document failed to convert after 60s.');
        }
      }).catch((error) => {
        reject(error);
      });
  }

  isConvertibleFile(file: string): boolean {
    return this.storageManagerService.fileHasValidExtension(
      file,
      this.storageManagerService.getValidExtensionsList(StorageManagerService.FILE_TYPE.DOCUMENT)
    );
  }

  convertFile(file: string, companyId?: string): Promise<number> {
    return this._getFiles(file, companyId)
      .then((result) => {
        if (result) {
          const converted = this._fileCount(result);
          if (converted) {
            return converted;
          }
        }
        return this.requestAssets(file, companyId);
      });
  }

  private _assetFileCount(result: any): number {
    if (result.length) {
      const metadataItem = result.find(item => item.id?.includes("-001.png"));
      const fileCount = metadataItem?.metadata?.assetSuccess_onedriveImages_imageCount;

      return fileCount ? parseInt(fileCount) : 0;
    }
    return 0;
  }

  private _pollAssetTimeout;
  private _pollAssetFiles(
    assetId: string,
    assetType: string,
    companyId: string,
    resolve: (value: unknown) => void,
    reject: (reason: any) => void,
    retries?: number
  ) {
    return this._getAssetFiles(assetId, assetType, companyId)
      .then((result) => {
        retries = retries || 0;

        const fileCount = result ? this._assetFileCount(result) : 0;

        if (fileCount && result.length === fileCount) {
          clearTimeout(this._pollAssetTimeout);
          return resolve(fileCount);

        } else if (retries < 60) {
          clearTimeout(this._pollAssetTimeout);
          this._pollAssetTimeout = setTimeout(() => {
            this._pollAssetFiles(assetId, assetType, companyId, resolve, reject, retries + 1);
          }, 1000);
        } else {
          reject('Failed to retrieve asset file count after 60s.');
        }
      }).catch((error) => {
        reject(error);
      });
  }

  private _getAssetFiles(assetId: string, assetType: string, companyId?: string): Promise<any> {
    return this.storageAPILoader()
      .then((storageApi) => {
        return storageApi.files.get({
          companyId: companyId || this.companyStateService.getSelectedCompanyId(),
          assetId,
          assetType,
        });
      })
      .then((response) => {
        if (response.result?.serviceAssets?.[assetId]) {
          return response.result?.serviceAssets?.[assetId];
        }
        throw new Error('Assets files not found.');
      });
  }

  getAssetCount(assetId: string, assetType: string, companyId?: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this._pollAssetFiles(assetId, assetType, companyId, resolve, reject);
    });
  }

}

angular.module('risevision.storage.services')
  .factory('documentService', downgradeInjectable(DocumentService));
