import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { BigQueryLogging, StorageAPILoader } from 'src/app/ajs-upgraded-providers';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class EncodingService {

  ENCODING_MASTER_SWITCH_URL = 'https://storage.googleapis.com/risemedialibrary/encoding-switch-on';

  disabled = false;
  masterSwitchPromise;

  constructor(
    private httpClient: HttpClient,
    private promiseUtilsService: PromiseUtilsService,
    private storageAPILoader: StorageAPILoader,
    private companyStateService: CompanyStateService,
    private bigQueryLogging: BigQueryLogging
  ) {
    console.debug('Loading encoding service');
    this.masterSwitchPromise = this.httpClient.head(this.ENCODING_MASTER_SWITCH_URL)
      .toPromise();
  }

  isApplicable (fileType, fileName) {
    console.debug('Checking encoding applicability for ' + fileType);

    if (this.disabled) {
      console.debug('Encoding disabled due to previous error');
      this.bigQueryLogging.logEvent('encoding disabled', fileName);
      return Promise.resolve(false);
    }

    if (fileType.indexOf('video/') !== 0) {
      return Promise.resolve(false);
    }
    if (fileName && fileName.indexOf('#') >= 0) {
      return Promise.resolve(false);
    }

    if (sessionStorage.getItem('force-upload-encoding') === 'true') {
      return Promise.resolve(true);
    }

    if (sessionStorage.getItem('bypass-upload-encoding') === 'true') {
      return Promise.resolve(false);
    }

    return this.masterSwitchPromise
      .then(() => {
        return true;
      }, () => {
        return false;
      });
  }

  disableEncoding () {
    this.disabled = true;
  }

  getResumableUploadURI (fileName) {
    var obj = {
      companyId: this.companyStateService.getSelectedCompanyId(),
      fileName: encodeURIComponent(fileName),
    };

    console.debug('Retrieving encoding upload destination', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.getEncodingUploadURI(obj);
      })
      .then(resp => {
        return resp.result;
      })
      .catch(e => {
        console.error('Error getting resumable upload URI', e);
        return Promise.reject(e);
      });
  }

  startEncoding (item) {
    var obj = {
      taskToken: item.taskToken,
      fileUUID: item.tusURL.split('/').pop(),
      fileName: encodeURIComponent(item.file.name),
      companyId: this.companyStateService.getSelectedCompanyId()
    };

    console.debug('Requesting encoding start', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.startEncodingTask(obj);
      })
      .then(resp => {
        return Promise.resolve({
          statusURL: resp.result.message
        });
      })
      .catch(e => {
        console.error('Error starting encoding task', e);
        return Promise.reject(e);
      });
  }

  monitorStatus (item, onProgress, retry?) {
    var statusURL = item.encodingStatusURL;
    var headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (retry === undefined) {
      retry = 0;
    }

    console.debug('Checking status at ' + statusURL);

    return this.promiseUtilsService.delay(5000)
      .then(() => {
        return this.httpClient.post(statusURL, 'task_tokens=' + item.taskToken, {
          headers: headers
        }).toPromise();
      })
      .then((resp: any) => {
        console.debug('Status: ', resp);

        if (resp.error) {
          return Promise.reject(resp.error);
        }

        var taskStatus = resp.statuses[item.taskToken];
        if (!taskStatus) {
          return this.monitorStatus(item, onProgress, retry + 1);
        }

        if (taskStatus.status_url) {
          item.encodingStatusURL = taskStatus.status_url;
        }

        if (taskStatus.error) {
          retry = Infinity;
          return Promise.reject(taskStatus.error_description);
        }

        if (taskStatus.percent !== 100 || taskStatus.status !== 'completed') {
          onProgress(taskStatus.percent);

          return this.monitorStatus(item, onProgress);
        }

        onProgress(taskStatus.percent);
      })
      .catch(e => {
        if (retry > 2) {
          return Promise.reject(e);
        }
        return this.monitorStatus(item, onProgress, retry + 1);
      });
  }

  acceptEncodedFile (fileName) {
    var obj = {
      companyId: this.companyStateService.getSelectedCompanyId(),
      fileName: encodeURIComponent(fileName),
    };

    console.debug('Accepting encoded file', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.acceptEncodedFile(obj);
      })
      .then(resp => {
        return resp.result;
      })
      .catch(e => {
        console.error('Error accepting encoded file', e);
        return Promise.reject(e);
      });
  }

}

angular.module('risevision.apps.services')
  .factory('encoding', downgradeInjectable(EncodingService));
