<div class="flex-grow flex-col flex-wrap aligner mt-5">
  @if (waiting) {
    <img src="/images/screen-sharing/screen-sharing-wait.png" width="40" height="40" class="mb-5" alt="Waiting">
    <div class="font-weight-bold py-5">Wait for the moderator to invite you to share</div>
  }
  @else if (screenSharingService.memberIsSharing) {
    @if (isPaused) {
      <img src="/images/screen-sharing/screen-sharing-paused.png" width="40" height="40" class="mb-5" alt="Paused">
      <strong class="mb-4">{{ isModerator ? "Sharing paused" : "Paused" }}</strong>
    }
    @else if (isStopped) {
      <img src="/images/screen-sharing/screen-sharing-stopped.png" width="40" height="40" class="mb-5" alt="Stopped">
      <strong class="mb-4">{{ isModerator ? "Sharing stopped" : "Stopped" }}</strong>
    }
    @else {
      <img src="/images/screen-sharing/screen-sharing-active.png" width="40" height="40" class="mb-5" alt="Sharing">
      <strong class="mb-4">Sharing your screen</strong>
    }
    @if (isModerator) {
      <p class="mb-4">Your screen is being shared to the display.</p>
    }
  }
  @else if (isModerator) {
    @if (screenSharingService.sharing) {
      <img src="/images/screen-sharing/user-circle-green.png" width="40" height="40" class="mb-5" alt="User Sharing">
      <strong class="mb-4">{{ screenSharingService.sharing.memberDisplayName }} is sharing</strong>
      <p class="mb-4">You may invite another participant to share in their place.</p>
    }
    @else {
      <img src="/images/screen-sharing/user-circle-gray.png" width="40" height="40" class="mb-5" alt="No User Sharing">
      <strong class="mb-4">No one is sharing</strong>
      <p class="mb-4">Select a participant to invite to share or share your screen.</p>
    }
  }

  @if (!isModerator) {
    @if (screenSharingService.channelError) {
      <div class="alert alert-danger">{{ screenSharingService.channelError }}</div>
    }
    <p class="pb-2">
      @if (waiting) {
        <div class="moderator-list">
          To share your screen, you must be invited by <span [innerHTML]="moderatorName"></span>
        </div>
      }
      @else if (isPaused) {
        Click "Resume" to resume screen sharing
      }
      @else if (isStopped) {
        Click "Start Sharing" to share your screen
      }
      @else {
        Your screen is being shared to the display
      }
    </p>
  }

  @if (!waiting) {
    @if (isStopped) {
      <button mat-flat-button color="primary" class="btn-block" (click)="start()" [disabled]="screenSharingService.connecting">
        @if (screenSharingService.connecting) {
          <mat-spinner diameter="15" class="white"></mat-spinner>
        } @else if (isModerator) {
          Share now
        } @else {
          Start Sharing
        }
      </button>
    }
    @else {
      @if (isPaused) {
        <button mat-stroked-button color="primary" class="btn-block" (click)="resume()">
          <mat-icon svgIcon="play" aria-hidden="true"></mat-icon>
          Resume
        </button>
      }
      @else if (screenSharingService.memberIsSharing && !rvshareAppDetectionService.isScreenShareApp()) {
        <button mat-stroked-button color="primary" class="btn-block" (click)="pause()">
          <mat-icon svgIcon="pause" aria-hidden="true"></mat-icon>
          Pause
        </button>
      }
      <button mat-stroked-button color="primary" class="btn-block" (click)="stop()">
        <mat-icon svgIcon="stop-circle" aria-hidden="true"></mat-icon>
        Stop
      </button>
      @if (!rvshareAppDetectionService.isScreenShareApp()) {
        @if (screenSharingService.muted) {
          <div class="alert alert-warning mt-5">
            Audio is not supported on this display.
          </div>
        }
        @else {
          <div class="w-100 flex flex-row my-4 gap-3">
            <mat-icon aria-hidden="true" svgIcon="volume" class="mr-1"></mat-icon>
            <mat-slider min="0" max="100" step="1" color="accent" class="flex-grow" [disabled]="screenSharingService.muted">
              <input matSliderThumb [(value)]="volume" (valueChange)="changeVolume($event)">
            </mat-slider>
            <div class="volume">{{ volume }}</div>
          </div>
        }
      }
    }
  }
</div>