import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { ProcessErrorCode, StorageAPILoader } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class StorageApiService {

  constructor(
    private processErrorCode: ProcessErrorCode,
    private companyStateService: CompanyStateService,
    private storageAPILoader: StorageAPILoader
  ) { }

  _mapFileNames (files) {
    if (files.items && files.items.length && files.items[0].name) {
      files = files.items.map(file => { return file.name; });
    }
    return files;
  }

  getFiles (search) {
    var obj: any = {
      'companyId': this.companyStateService.getSelectedCompanyId()
    };

    if (search.folderPath) {
      obj.folder = search.folderPath;
    }
    if (search.file) {
      obj.file = search.file;
    }

    console.debug('Storage files get called with', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.files.get(obj);
      })
      .then(resp => {
        console.debug('status storage files resp', resp);

        return resp.result;
      })
      .catch(e => {
        var apiError = this.processErrorCode('Files', 'list', e);
        console.error('Failed to get storage files', apiError);
        return Promise.reject(e);
      });

  }

  deleteFiles (selectedFileNames) {
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'data': {
        'files': this._mapFileNames(selectedFileNames)
      }
    };

    console.debug('Storage delete called with', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.files.delete(obj);
      })
      .then(resp => {
        console.debug('status storage delete resp', resp);

        return resp.result;
      })
      .catch(e => {
        console.error('Failed to delete files', e);
        return Promise.reject(e);
      });

  }

  moveToTrash (selectedFileNames) {
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'data': {
        'files': this._mapFileNames(selectedFileNames)
      }
    };

    console.debug('Storage trash move called with', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.trash.move(obj);
      })
      .then(resp => {
        console.debug('status storage trash move resp', resp);

        return resp.result;
      })
      .catch(e => {
        console.error('Failed to move files to trash', e);
        return Promise.reject(e);
      });
  }

  restoreFromTrash (selectedFileNames) {
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'data': {
        'files': this._mapFileNames(selectedFileNames)
      }
    };

    console.debug('Storage trash restore called with', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.trash.restore(obj);
      })
      .then(resp => {
        console.debug('status storage trash restore resp', resp);

        return resp.result;
      })
      .catch(e => {
        console.error('Failed to restore files from trash', e);
        return Promise.reject(e);
      });
  }

  createFolder (folder) {
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'folder': folder
    };

    console.debug('Creating folder: ', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.createFolder(obj);
      })
      .then(resp => {
        console.debug('Folder created', resp);

        return resp.result;
      })
      .catch(e => {
        console.error('Failed to create folder', e);
        return Promise.reject(e);
      });
  }

  getFolderContents (folderName) {
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'folderName': folderName
    };

    console.debug('Retrieving folder contents: ', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.getFolderContents(obj);
      })
      .then(resp => {
        console.debug('Folder contents retrieved', resp);

        return resp.result;
      })
      .catch(e => {
        console.error('Failed to retrieve folder contents', e);
        return Promise.reject(e);
      });
  }

  getResumableUploadURI (fileName, fileType) {
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'fileName': encodeURIComponent(fileName),
      'fileType': fileType,
      'origin': window.location.origin
    };

    console.debug('getting resumable upload URI: ', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.getResumableUploadURI(obj);
      })
      .then(resp => {
        console.debug('getting resumable upload URI finished', resp);

        return resp.result;
      })
      .catch(e => {
        console.error('Error getting resumable upload URI', e);
        return Promise.reject(e);
      });
  }

  getSignedDownloadURI (file) {
    var obj = {
      companyId: this.companyStateService.getSelectedCompanyId(),
      fileName: encodeURIComponent(file.name),
      fileType: file.type
    };

    console.debug('getting signed download URI: ', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.getSignedDownloadURI(obj);
      })
      .then(resp => {
        console.debug('getting signed download URI finished', resp);

        return resp.result;
      })
      .catch(e => {
        console.error('Error getting signed download URI', e);
        return Promise.reject(e);
      });
  }

  rename (sourceName, destinationName) {
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'sourceName': sourceName,
      'destinationName': destinationName
    };

    console.debug('Storage rename called with', obj);

    if (sourceName === destinationName) {
      return Promise.reject({
        status: 400,
        result: {
          error: {
            message: 'must-be-different'
          }
        }
      });
    } else {
      return this.storageAPILoader().then(storageApi => {
          return storageApi.files.rename(obj);
        })
        .then(resp => {
          console.debug('status storage rename resp', resp);

          return resp.result;
        })
        .catch(e => {
          console.error('Failed to rename storage objects', e);
          return Promise.reject(e);
        });
    }
  }

  duplicate (sourceName) {
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'sourceName': sourceName
    };

    console.debug('Storage duplicate called with', obj);

    return this.storageAPILoader().then(storageApi => {
        return storageApi.files.duplicate(obj);
      })
      .then(resp => {
        console.debug('status storage duplicate resp', resp);

        return resp.result;
      })
      .catch(e => {
        console.error('Failed to duplicate storage objects', e);
        return Promise.reject(e);
      });

  }

  refreshFileMetadata (fileName, remainingAttempts = 2) {
    console.log('Attempt #' + remainingAttempts + ' to get metadata for: ' + fileName);

    return this.getFiles({
        file: fileName
      })
      .then(resp => {
        var file = resp && resp.files && resp.files[0];

        if (file && (!file.metadata || file.metadata['needs-thumbnail-update'] !== 'true')) {
          return file;
        } else if (file && remainingAttempts > 0) {
          return this.refreshFileMetadata(fileName, remainingAttempts - 1);
        } else {
          console.log('Error refreshing metadata', file.name);

          return file;
        }
      });
  }

}

angular.module('risevision.apps.services')
  .factory('storage', downgradeInjectable(StorageApiService));