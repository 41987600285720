import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ComponentsService } from '../../services/components.service';
import { PlaylistService } from '../services/playlist.service';
import { UserComponentsService } from '../services/user-components.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { UpgradeNoticeComponent } from 'src/app/components/plans/upgrade-notice/upgrade-notice.component';

@Component({
  selector: 'component-playlist-item-selector',
  templateUrl: './playlist-item-selector.component.html',
  styleUrls: ['./playlist-item-selector.component.scss']
})
export class PlaylistItemSelectorComponent {

  isUserComponents: boolean = false;
  playlistComponents: any[] = [];

  @ViewChildren('UpgradeNotice') notices: QueryList<UpgradeNoticeComponent>;

  constructor(
    public playlistService: PlaylistService,
    public userComponentsService: UserComponentsService,
    public featuresService: FeaturesService,
    private componentsService: ComponentsService,
    private elementRef: ElementRef
  ) {
    componentsService.registerDirective({
      type: 'rise-playlist-item-selector',
      element: this.elementRef.nativeElement,
      show: (options?) => {
        this.playlistComponents = options?.userComponents ? this.userComponentsService.playlistComponents : this.playlistService.playlistComponents;
        this.isUserComponents = options?.userComponents;

        setTimeout(() => {
          this.notices.forEach((notice) => {
            notice.popover.onShown.subscribe(() => {
              this.notices.filter(n => n !== notice).forEach(n => n.popover.hide());
            });
          });
        });
      },
      getLabel: id => {
        return this.isUserComponents ? 'Select a component' : null;
      }
    });
  }

  addItem(type: string, options?, requireFeature?) {
    if (requireFeature && !this.featuresService.isFeatureAvailable(requireFeature)) {
      this.featuresService.showUpgradePlanModal(requireFeature);
    } else {
      const addItemFunc = this.isUserComponents ? this.userComponentsService.addItem.bind(this.userComponentsService) : this.playlistService.addItem.bind(this.playlistService);

      this.componentsService.showPreviousPage();
      addItemFunc(type, options);
    }
  }

  getPlaylistComponents() {
    return this.isUserComponents ? this.userComponentsService.playlistComponents : this.playlistService.playlistComponents;
  }

}
