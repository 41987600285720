import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from 'src/environments/environment';

export type ApiSearchLegacy = {
  query?: string;
  filter?: string;
  count?: number | string;
  sortBy?: string;
  reverse?: boolean
}

export type ApiSearch = {
  query?: string;
  filter?: string;
  search?: string;
  includeSubcompanies?: boolean,
  count?: number | string;
  sortBy?: string;
  reverse?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ApiUtilsService {

  constructor() { }

  getError(error?: any): any {
    if (error) {
      if (error.result) {
        if (error.result.error) {
          return error.result.error;
        } else {
          return error.result;
        }
      } else if (error.error) {
        return error.error;
      } else {
        return error;
      }
    } else {
      return {};
    }
  }

  humanReadableError(resp?: any): string {
    if (!resp) {
      return 'Unknown Error';
    }

    const error = this.getError(resp);
    const message = error.message || error;

    if (typeof message === 'string') {
      return message;
    } else {
      return JSON.stringify(message);
    }
  }

  isAuthorizedEndpoint(url: string): boolean {
    return url.startsWith(environment.SPRING_ENDPOINT_URL) ||
      url.startsWith(environment.CORE_URL) ||
      url.startsWith(environment.STORE_ENDPOINT_URL) ||
      url.startsWith(environment.STORAGE_ENDPOINT_URL);
  }
}

angular.module('risevision.apps.services')
  .factory('apiUtils', downgradeInjectable(ApiUtilsService));
